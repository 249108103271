@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&family=Montserrat:wght@300;400;500;600;700;800;900&display=swap");

body {
  background-color: #f7f7f7;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 100px;
}

.tox-dialog__footer-end button.tox-button:not(.tox-button--secondary) {
  background-color: #1e1e1e !important;
  border-color: #1e1e1e !important;
}

.tox .tox-listboxfield .tox-listbox--select:focus,
.tox .tox-textarea:focus,
.tox .tox-textfield:focus {
  border-color: #daa8e2 !important;
}
.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid #1e1e1e !important;
  font-weight: 600;
  color: #1e1e1e !important;
}
.tox .tox-checkbox__icons .tox-checkbox-icon__checked svg {
  fill: #ff3250 !important;
}
.tox input.tox-checkbox__input:focus + .tox-checkbox__icons {
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px #ff3250 !important;
  padding: calc(4px - 1px);
}

.OTP_BoxStyle {
  display: flex;
  width: 4rem;
  height: 4rem;
  margin: 1rem;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: 800;
  border: 2px solid #98a0a6;
}

/* Media query for small screens (up to 576px) */
@media (max-width: 576px) {
  html {
    font-size: 10px;
  }
  .menu {
    display: none !important;
  }
}

/* Media query for medium screens (between 577px and 768px) */
@media (min-width: 577px) and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}

/* Media query for large screens (between 769px and 992px) */
@media (min-width: 769px) and (max-width: 992px) {
  html {
    font-size: 14px;
  }
}

/* Media query for extra-large screens (greater than 992px) */
@media (min-width: 993px) {
  html {
    font-size: 16px;
  }
}
